import styles from "./ItemPage.module.css";
import State from "../../state";
import config from "../../config";
import { Button } from "../common/buttons";
import MarkdownContent from "../common/MarkdownContent";

import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import find from "lodash/find";

export default function ItemPage({ page }) {
	const { itemId } = useParams();
	const item = find(page.items, (x) => x.id === itemId);

	return (
		<div className={styles.page}>
			<Item item={item} />
		</div>
	);
}

function Item({ item }) {
	const isBuy = item.type === "buy";

	return (
		<div className={styles.item}>
			<div className={styles.item__imageWrapper}>
				<img
					src={item.image}
					className={styles.item__image}
					alt={item.title}
				/>
			</div>
			<div className={styles.item__info}>
				<div className={styles.item__title}>{item.title}</div>
				<div className={styles.item__props}>
					<ItemProp
						title="Price"
						value={`${item.price.toLocaleString()} ${
							config.site.coinPlural
						}`}
					/>
					<ItemProp
						title={isBuy ? "Seller" : "Buyer"}
						value={item.user}
					/>
				</div>
				{item.description ? (
					<div className={styles.item__description}>
						<div className={styles.item__description__title}>
							{"Description"}
						</div>
						<MarkdownContent
							className={styles.item__description__text}
							markdown={item.description}
						/>
					</div>
				) : null}
				{item.available === false ? (
					<UnavailableItemMessage item={item} />
				) : isBuy ? (
					<BuyForm item={item} />
				) : (
					<SellForm item={item} />
				)}
			</div>
		</div>
	);
}

function ItemProp({ title, value }) {
	return (
		<div className={styles.item__prop}>
			<span className={styles.item__prop__title}>{`${title} : `}</span>
			<span className={styles.item__prop__value}>{value}</span>
		</div>
	);
}

const BuyForm = observer(({ item }) => {
	const { coins, ongoingTransaction, purchasedItems } = State;
	const { id, price, prompt } = item;

	const [text, setText] = useState("");
	const [error, setError] = useState(null);

	function handleSubmit(evt) {
		setError(null);
		evt.preventDefault();
		State.buyItem(item, text).then(({ success, error: errorMsg }) => {
			setError(success ? null : errorMsg);
		});
	}

	const purchasedItem = purchasedItems[id];
	if (purchasedItem) {
		return <PurchasedItem item={purchasedItem} />;
	}

	const enoughCoins = price <= coins;
	const disabled = !enoughCoins || (prompt && !text);
	const coinStr = pluralize(
		price,
		config.site.coinSingular,
		config.site.coinPlural
	);

	return (
		<form className={styles.form} action="#" onSubmit={handleSubmit}>
			{prompt ? (
				<textarea
					className={styles.buyForm__textbox}
					value={text}
					placeholder={prompt}
					onChange={(evt) => setText(evt.target.value)}
				/>
			) : null}
			<Button
				inline
				large
				className={styles.actionButton}
				disabled={disabled}
				loading={ongoingTransaction}
				text={`Buy for ${price.toLocaleString()} ${coinStr}`}
			/>
			<FormError error={error} />
		</form>
	);
});

const PurchasedItem = observer(({ item }) => (
	<div className={styles.purchasedItem}>
		<div className={styles.purchasedItem__title}>
			{"Thank you for your purchase!"}
		</div>
		<div className={styles.purchasedItem__subtitle}>
			{"Here is your item:"}
		</div>
		<MarkdownContent
			className={styles.purchasedItem__text}
			markdown={item}
		/>
	</div>
));

const SellForm = observer(({ item }) => {
	const { id, prompt } = item;
	const { ongoingTransaction, soldItems } = State;

	const [value, setValue] = useState("");
	const [error, setError] = useState(null);

	const handleSubmit = (evt) => {
		setError(null);
		evt.preventDefault();
		State.sellItem(item, value).then(({ success, error: errorMsg }) => {
			setError(success ? null : errorMsg);
		});
	};

	const soldItem = soldItems[id];
	if (soldItem) {
		return <SoldItem message={soldItem} />;
	}

	return (
		<form className={styles.form} action="#" onSubmit={handleSubmit}>
			<textarea
				className={styles.sellForm__textbox}
				value={value}
				placeholder={
					prompt || "Put your goods here and click Sell item."
				}
				onChange={(evt) => setValue(evt.target.value)}
			/>
			<Button
				large
				className={styles.actionButton}
				disabled={value.trim() === ""}
				loading={ongoingTransaction}
				onClick={handleSubmit}
				text="Sell item"
			/>
			<FormError error={error} />
		</form>
	);
});

const SoldItem = observer(({ message }) => (
	<div className={styles.purchasedItem}>
		<div className={styles.purchasedItem__title}>
			{"Secure transaction complete!"}
		</div>
		<div className={styles.purchasedItem__subtitle}>
			{"Here's the buyer's response:"}
		</div>
		<MarkdownContent
			className={styles.purchasedItem__text}
			markdown={message}
		/>
	</div>
));

function UnavailableItemMessage({ item }) {
	const isBuy = item.type === "buy";

	return (
		<div className={styles.unavailableItem}>
			{isBuy
				? config.site.unavailableBuyText
				: config.site.unavailableSellText}
		</div>
	);
}

function FormError({ error }) {
	if (!error) {
		return null;
	}

	return <div className={styles.form__error}>{error}</div>;
}

function pluralize(num, singular, plural) {
	return num === 1 ? singular : plural;
}
